.main-recursos-beneficios{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: go-back 2s
}

.main-recursos-beneficios h2 {
  font-size: 35px;
  padding: 20px;
}

.main-recursos-beneficios img{
  width: 70%;
  padding: 10px;
}

.itens-recursos-beneficios{
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EFF3F7;
}

.recursos{
  width: 50%;
  border-right: solid black 0.2px;
  padding: 20px;
}

.recursos h3{
  text-align: center;
}

.recursos ul li{
  margin-bottom: 10px;
}

.beneficios h3{
  text-align: center;
}

.beneficios{
  width: 50%;
  padding: 20px;
}

.beneficios ul li{
  margin-bottom: 10px;
}
.button-lytx{
  display: block;
  border-radius: 5px;
  margin-top: 30px;
  
}

.button-lytx a{
  padding: 10px;
  text-decoration: none;
  background-color: aliceblue;
  cursor: pointer;
  border-radius: 9px;
}

@media (max-width: 900px){
  .main-recursos-beneficios h2 {
    text-align: center;
  }
  .main-recursos-beneficios img{
    width: 100%;
  }
  .itens-recursos-beneficios{
    flex-direction: column;
  }
  .recursos{
    width: 100%;
    border-right: none;
    border-bottom: solid black 0.2px;
    padding-bottom: 50px;
    margin-bottom: 40px;
  }
  .recursos h3{
    margin-bottom: 20px;
  }
  .recursos ul{
    list-style: circle;
  }
.beneficios h3{
  margin-bottom: 20px;
}
  .beneficios{
    width: 100%;
    text-align: justify;
  }

  .beneficios ul{
    list-style: circle;
  }
}
