.main-policy{
  width: 100%;
  display: flex;
  min-height: 500px;
  justify-content: center;
}

.text-policy{
  width: 80%;
  background: white;
  border-radius: 50px;
  padding: 60px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  top: 20%;
}

@media (max-width: 900px) {
  .text-policy{
    width: 90%;
    padding: 40px;
  }
}