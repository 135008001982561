.main-cont-preps{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eff3f7;
  padding: 20px;
  text-align: justify;
  gap: 100px;
}

.text-main-cont-preps{
  width: 50%;
  border-right: 1px solid black;
  padding-right: 40px;
  animation: go-back 4s ;
}

.text-main-cont-preps h4{
  margin-bottom: 50px;
}



@media (max-width: 900px) {
  .main-cont-preps{
    flex-direction: column-reverse;
    background: #EFF3F7;
    padding: 20px;
  }
  .text-main-cont-preps{
    width: 90%;
    border-right: none;
    padding: 20px 0;
    text-align: center;
  }
}