.about-us{
  width: 100%;
  display: flex;
  height: 500px;
  justify-content: center;
}

.text-about-us{
  width: 70%;
  background: white;
  border-radius: 50px;
  padding: 60px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 95%;
  text-align: center;
}

.text-about-us h1{
  margin-bottom: 20px;
}

@media (max-width: 900px){
  .about-us{
    height: 450px;
  }
  .text-about-us{
    width: 90%;
    font-size: 16px;
    top: 65%;
    padding: 40px;
  }
}

@media (max-width: 700px){
  .text-about-us{
    width: 90%;
    font-size: 15px;
    top: 65%;
    padding: 40px;
  }
}

@media (max-width: 500px){
  .text-about-us{
    width: 90%;
    font-size: 14px;
    top: 65%;
    padding: 20px;
  }
}
@media (max-width: 375px){
  .text-about-us{
    width: 90%;
    font-size: 14px;
    top: 68%;
    padding: 11px;
  }
}