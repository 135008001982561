.main-Envio-form{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  width: 100%;
}

.Envio-form{
  color: #fff;
}

.Envio-form button{
  height: 60px;
  width: 200px;
  background: #fff;
  border-radius: 50px;
  border: #fff solid 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}


.Envio-form button a{
  color: #000;
  text-decoration: none;
  font-size: 16px;
}

.img-enviado img{
  width: 500px;
}

@media (max-width: 900px){
  .main-Envio-form{
    flex-direction: column-reverse;
  }
  .Envio-form{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Envio-form h1{
    font-size: 20px;
  }
  .Envio-form p{
    font-size: 14px;
  }
  .img-enviado img{
    margin: 20px;
    width: 200px;
  }
}