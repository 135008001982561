@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.button-whats{
  width: 50px;
  position: fixed;
  bottom: 80px;
  right: 40px;
  transition: 0.2s;
}

.button-whats:hover{
  width: 80px;
}

.button-whats img{
  width: 100%;
}