.bannerGeo{
  background-image: url("../../image/telemetria_automotiva.png");
  background-size: 100%;
  width: 100%;
  height: 100vh;
  
}

.bannerGeo h1{
  color: #fff;
  left: 60%;
  top: 30%;
  position: absolute;
  width: 300px;
  
}

@media (max-width: 900px) {
  .bannerGeo{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    object-fit: cover;
    height: 500px;
  }

  .bannerGeo h1{
    width: 150px;
    font-size: 18px;
    top: 20%;
  }
}

@media (max-width: 700px) {
  .bannerGeo{
    background-repeat: no-repeat;
    background-size: 100% 60%;
    object-fit: cover;
    height: 500px;
  }

  .bannerGeo h1{
    width: 150px;
    font-size: 18px;
    top: 20%;
  }
}