.banner-preps{
  background-image: url("https://dialogochino.net/content/uploads/2020/12/Giant-squid-Chinese-fishing-Galapagos.jpg");
  background-size: 100%;
  height: 100vh;
  display: flex;
  justify-content: left;
  align-items: center;
}

.banner-preps h2{
  font-size: 42px;
  color: #fff;
  max-width: 50%;
  margin-left: 120px;
  animation: go-back 1s ;
}

@keyframes go-back{
  0% {
      transform: translateX(100px);
  }
  100% {
      transform: translateX(0);
  }
}

@media (max-width: 900px) {
  .banner-preps{
    background-size: 100% 100%;
    height: 450px;
  }
  .banner-preps h2{
    font-size: 20px;
    width: 45%;
    margin-left: 50px;
    color: #fff;
    
  }
}

@media (max-width: 700px) {
  .banner-preps{
    background-size: 100% 350px;
    height: 350px;
  }
  .banner-preps h2{
    font-size: 22px;
    margin-left: 50px;
    color: #fff;
  }
}