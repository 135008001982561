.container{
  margin-top: 60px;
}

.container li{
  color: #000;
}

.contato-footer{
  color: rgb(99, 99, 99);
  
}
.contato-footer{
  padding-left: 0.8rem;
}
.contato-footer li{
  list-style: none;
  
}
.contato-footer a{
  width: 100%;
  text-decoration: none;
  color: rgb(99, 99, 99);
}
.contato-footer a:hover{
  color: rgb(0, 0, 0);
  transition: 0.7s;
}