.why-product{
  margin-top: 40px;
  display: flex;
  flex-direction: reverse;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: justify;
  gap: 100px;
}
.why-productinfo{
  width: 50%;
  border-right: 1px solid black;
  padding-right: 60px;
}
.why-productinfo h4{
  margin-bottom: 50px;
}

.why-product img{
  border-radius: 4px;
}
