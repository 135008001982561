.servicos-home{
  padding: 100px 90px; 
  width: 100%;
}

.carousel-item img{
  height: 500px;
  object-fit: cover;
}

@media (max-width: 900px){
  .servicos-home{
    padding: 50px 10px ;
  }

  .carousel-item img{
    height: 350px;
    width: 100%;
  }

}

@media (max-width: 700px){
  .servicos-home{
    width: 100%;
    padding: 90px 10px ;
    margin-top: 20px;
  }

  .carousel-item img{
    height: 350px;
    object-fit: contain;
  }
}
@media (max-width: 550px){
  .servicos-home{
    width: 100%;
    padding: 30px 10px ;
    margin-top: 170px;
  }
  .carousel-item img{
    height: 300px;
    width: 400px;
    object-fit: cover;
  }
}

