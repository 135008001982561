.main-graficos{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center ;
  padding: 20px 40px;
  background: #EFF3F7;
}

.component-grafico{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/*animação*/
.component-grafico {
  transition: transform 1s;
  transform: translateX(1.5) scale(0.5);
}
.component-grafico:hover,
button:focus {
  transform: translateX(10px) scale(1.2);
}



@media (max-width: 900px) {
  .component-grafico{
    font-size: 12px;
    text-align: center;
  }
  .main-graficos{
    padding:20px;
  }
}

@media (max-width: 900px) {
  .component-grafico{
    font-size: 12px;
    text-align: center;
  }
  .main-graficos{
    padding:20px;
  }
}