.cabecalho{
  width: 100%;
  position: fixed;
  min-height: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 80px; 
  z-index: 9999999;
  transition: 0.4s;
}

.cabecalho.active{
  background-color: #23232e;
}

.logo img{
  width: 220px;
}

.menus{
  width: 80%;
}

.menus ul, .menus li{
  display: flex;
  padding: 0 20px;
  font-size: 18px;
  flex-flow: nowrap row;
  justify-content: end;
}

.menus a{
  text-decoration: none;
  color: #ffff
}

.contato{
  background-color: #0078d3;
  border-radius: 10px;
}

.contato:hover{
  background-color: #ffff;
  transition: 0.6s;
  color: #000;
}

@media (max-width: 950px){
  .menus ul li{
    font-size: 16px;
  }
  .menus{
    width: 100%;
    height: 50px;
  }

}

@media (max-width: 900px){
  .cabecalho{
    justify-content: space-around;
    gap: 200px;
    padding: 10px 40px;
  }

  .logo img{
    width: 120px;
  }
  .menus{
    width: 100%;
    height: 50px;
  }
  .menus ul li{
    font-size: 15px;
    padding: 12px;
  }
}


@media (max-width: 850px){
  .cabecalho{
    padding: 10px;
    min-height: 30px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .logo img{
    width: 130px;
  }
  .menus{
    width: 100%;
    height: 50px;
  }
  .menus ul{
    justify-content: center;
    text-align: center;
  }
  .contato:hover{
    background: none;
  }
  
  .menus ul li{
    font-size: 13px;
    padding: 12px;
  }
}
@media (max-width: 850px){
  .cabecalho{
    padding: 10px;
    min-height: 30px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .logo img{
    width: 120px;
  }
  .menus{
    width: 100%;
    height: 50px;
  }
  .menus ul{
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .contato:hover{
    background: none;
  }
  
  .menus ul li{
    font-size: 12px;
    padding: 10px;
  }
}