.form-geotab{
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  min-height: 600px;
}

.col-info-Geotab{
  width: 100%;
  margin-bottom: 10px;
}

.informacoes-Geotab{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.botao-Geotab{
  margin-top: 20px;
  text-align: center;
}

.demo h4{
  text-align: center;
  margin-bottom: 30px;
  
}
.comodato{
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}
.info-adicional{
  color: red;
}

@media (max-width: 900px){
  .form-geotab{
    width: 90%;
  }
  .demo h4{
    font-size: 18px;  
  }
  .informacoes-Geotab{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
}