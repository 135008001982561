.servicos-telemetria{
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  margin-top: 50px;
}

.m2m{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 300px;
  text-align: justify;
  animation: go-back 2s
}

.m2m img{
  width: 25%;
}

.agricultura{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: justify;
  min-height: 300px;
  animation: go-back 2s
}

.agricultura img{
  width: 25%;
}

@media (max-width: 900px) {
  .servicos-telemetria{
    flex-direction: column;
  }
  .m2m{
    width: 100%;
    border-bottom: 1px solid black;
  }
  .agricultura{
    width: 100%;
  }
}