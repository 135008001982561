.Lytx-introducao{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 50px;
  flex-direction: column;
  margin-bottom: 50px;

  
}
.sobre-Form h3{
  text-align: center;
  margin-bottom: 55px;
}
.sobre-Form img{
  max-width: 900px;
  border-radius: 5px;
}
.demo h3{
  margin-bottom: 50px;
  text-align: center;
}

.Lytx-introducao p{
  width: 800px;
  
}

.col-info-Lytx img{
  max-width: 100%;
  height: 250px;
}
.diferenciais-Lytx{
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  text-align: center;
  flex-wrap: wrap;
}
.grid-Lytx{
  display: grid;
  grid-template-columns: 450px 450px;
  gap: 50px;
  margin: 0 auto;
  padding: 30px;
  justify-content: center;
}
.col-info-Lytx{
  background-color: rgb(253, 254, 254);
  border-radius: 3px;
  border: solid 1px rgba(40, 210, 240, 0.123);
  
}
.col-info-Lytx h2{
  color: rgb(0, 173, 253);
  font-size: 18px;
}


.text-info-lytx{
  text-align: left;
  padding: 25px;
  font-size: 17px;
}
.info-cliente{
  margin-top: 10px;
  color: red;
}

@media(max-width: 900px) {
  .sobre-Form img{
    max-width: 680px;
    border-radius: 5px;
  }
  .sobre-camera {
    max-width: 680px;
  }
}
@media(max-width: 700px) {
  .sobre-Form img{
    max-width: 500px;
    border-radius: 5px;
  }
  .sobre-camera {
    max-width: 500px;
  }
}

@media(max-width: 500px) {
  .sobre-Form img{
    max-width: 365px;
    border-radius: 5px;
  }
  .sobre-camera {
    max-width: 360px;
  }
}