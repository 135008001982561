.conteudo-info{
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 400px;
}

.caixa-info{
  width: 80%;
  background: #fff;
  border-radius: 50px;
  padding: 60px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 90%;
  text-align: center;
  animation: go-back 1s
}

@media (max-width: 900px) {
  .caixa-info{
    width: 90%;
    font-size: 15px;
    top: 50%;
    padding: 45px;
  }
  .conteudo-info{
    min-height: 350px;
  }
}

@media (max-width: 700px) {
  .caixa-info{
    width: 90%;
    font-size: 15px;
    top: 30%;
    padding: 45px;
  }
  .conteudo-info{
    min-height: 350px;
  }
}

@media (max-width: 500px) {
  .caixa-info{
    width: 90%;
    font-size: 14px;
    top: 30%;
    padding: 35px;
  }
  .conteudo-info{
    min-height: 400px;
  }
}
@media (max-width: 400px) {
  .caixa-info{
    width: 90%;
    font-size: 14px;
    top: 30%;
    padding: 30px;
  }
  .conteudo-info{
    min-height: 420px;
  }
}