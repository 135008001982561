.about-us-telemetria{
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 150px;
  position: relative;
  
}

.texto-about-telemetria{
  width: 70%;
  background: #fff;
  border-radius: 50px;
  padding: 60px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: -10%;
  text-align: center;
  animation: go-back 1s
}
@keyframes go-back{
  0% {
      transform: translateX(100px);
  }
  100% {
      transform: translateX(0);
  }
}

@media (max-width: 900px) {
  .texto-about-telemetria{
    width: 90%;
    font-size: 12px;
    padding: 40px;
    top: -20%;
  }
}

@media (max-width: 700px) {
  .texto-about-telemetria{
    width: 90%;
    font-size: 12px;
    padding: 40px;
    top: -20%;
  }
}

@media (max-width: 485px) {
  .texto-about-telemetria{
    width: 90%;
    font-size: 12px;
    padding: 40px;
    top: -20%;
  }
  .about-us-telemetria{
    min-height: 190px;
  }
}
  @media (max-width: 380px) {
    .texto-about-telemetria{
      width: 90%;
      font-size: 12px;
      padding: 40px;
      top: -20%;
    }
    .about-us-telemetria{
      min-height: 230px;
    }
}
