.banner-lytx{
  background-image: url("../../image/camera-para-frotas.jpg");
  background-size: 100% 105%; 
  height: 100vh;
  display: flex;
  justify-content: left;
  align-items: center;
  background-repeat:no-repeat ;
}

.banner-lytx h2{
  width: 30%;
  color: #fff;
  margin-left: 100px;
  animation: go-back 1s
}

@keyframes go-back{
  0% {
      transform: translateX(100px);
  }
  100% {
      transform: translateX(0);
  }
}
@media (max-width: 900px){
  .banner-lytx{
    background-size: 100% 100%;
    height: 450px;
  }

  .banner-lytx h2{
    width: 30%;
    color: #fff;
    font-size: 17px;
    margin-left: 30px;
  }
}

@media (max-width: 700px){
  .banner-lytx{
    background-size: 100% 350px;
    height: 350px;
  }

  .banner-lytx h2{
    width: 30%;
    color: #fff;
    font-size: 17px;
    margin-left: 30px;
  }
}