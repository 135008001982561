.wrapper{
  position: fixed;
  bottom: 20px;
  left: 30px;
  background: #212121;
  max-width: 350px;
  border-radius: 15px;
  text-align: center;
  padding: 25px 25px 30px 25px;
  z-index: 99999999999999999;
}

.wrapper.hide{
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  display: none;
}

.wrapper img{
  max-width: 100px;
}

.wrapper .content{
  color: #fff;
  margin-top: 10px;
}

.content header{
  font-size: 25px;
}

.content p{
  color: #858585;
  margin: 5px 0 20px 0;
}

.btn-Cookie button{
  padding: 10px 20px;
  border: none;
  outline: none;
  font-size: 16px;
  background: #03001c;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}