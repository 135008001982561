.main-redes{
  width: 100%;
  min-height: 300px;
}

.main-redes h3{
  text-align: center;
  margin-bottom: 30px;
}

.image-redes{
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.image-redes img{
  width: 230px;
  height: 400px; 
  box-shadow: 10px 10px 10px  rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.blog-div{
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-img{
  padding: 10px;
  width: 800px;
  
}

.blog-img img{
  border-radius: 15px;
  width: 100%;
  box-shadow: 10px 10px 10px  rgba(0, 0, 0, 0.3);
}


@media (max-width: 900px) {
  .image-redes{
    gap: 25px;
  }

  .image-redes img{
    width: 220px;
    height: 420px;
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .image-redes{
    gap: 15px;
    min-height: 350px;
  }
  .main-redes h3{
    text-align: center;
    margin-bottom: 0px;
  }


  .image-redes img{
    width: 115px;
    height: 250px;
    flex-direction: column;
  }

  .blog-div{
    min-height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .blog-img{
    padding: 10px;
    width: 100%;
    
  }
}
@media (max-width: 550px) {
  .image-redes{
      min-height: 350px;
  }
  .main-redes{
   margin-top: 50px;
  }
}