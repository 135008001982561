.container-recursos{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  
}

.main-recursos{
  width: 50%;
  height: 25%;
  margin: 80px auto;
  background: #99aab5;
  border-radius: 20px;
  padding: 20px;
  animation: go-back 1s
}

.main-recursos h1{
  padding: 20px;
  text-align: center;
}

.itens-recursos ul{
  list-style: none;
  height: 100%;
}
.img-geotab img{
  max-width: 250px;
  
}


@media (max-width: 900px) {
  .main-recursos{
    width: 90%;
    height: 25%;
    margin: 50px auto;
  }
}