.container-form{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  background-color: black;
  align-items: center ;
}

form{
  margin-top: 40px;
  background: #FFFF;
  width: 600px;
  display: flex;
  min-height: 400px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}

.informacoes{
  width: 50%;
  padding: 20px;
}

.col-info{
  padding: 5px 0;
  width: 70%;
  font-size: 13px;
}

.form-control{
  width: 250px;
  border: none;
  box-shadow: 0 0 0 0 ;
  outline: 0;
  border-radius: 0;
  border-bottom: 1px solid black;
}

.btn-envio{
  border: #01d28e;
  background: #01d28e;
  color: #fff;
  padding: 10px;
  margin: 0 34px;
  cursor: pointer;
}

.btn-envio:hover{
  background: #28a745;
  transition: 0.1s;
}


.mensagem-form img{
  width: 300px;
}

.mensagem-form{
  color: #ffff;
  text-align: center;
}

.mensagem-form h1{
  font-size: 15px;
}

.mensagem-form p{
  font-size: 12px;
}

.main-infoCont-geral{
  margin-top: 60px;
}

.info-adicionais-form{
  background: #01d28e;
  height: 600px;
  width: 50%;
  padding: 50px 20px;
}

.menu-info-adicionais{
  display: flex;
  align-items: center;
  font-size: 15px;
  width: 100%;
}

.menu-info-adicionais a{
  text-decoration: none;
  color: #343a40;
}

.icon-contato{
    margin: 0 10px 18px;
}
.servicos{
  border: solid green 1px;
  background-color: #01d28e;
  padding: 3px 4px;
  border-radius: 5px;
  color: #fff;
}

@media (max-width: 900px) {
  .container-form{
    flex-direction: column-reverse;
    padding: 100px 0 0;
    height: 1200px;
  }
  .menu-info-adicionais{
    height: 70px;
  }
  .menu-info-adicionais a{
    width: 100%;
    font-size: 13px;
  }
  .mensagem-form{
    width: 90%;
    margin-bottom: 80px;
  }

  form{
    width:80%;
  }

  .col-info{
    font-size: 12px;
  }

  .form-control{
    width: 170px;
    font-size: 11px;
  }

  .btn-envio{
    margin: 0 20px;
  }
}

@media (max-width: 700px) {
  .container-form{
    flex-direction: column-reverse;
    padding: 100px 0 0;
    height: 1200px;
  }
  .menu-info-adicionais{
    height: 70px;
  }
  .menu-info-adicionais a{
    width: 100%;
    font-size: 13px;
  }
  .mensagem-form{
    width: 90%;
    margin-bottom: 80px;
  }

  form{
    width: 99%;
  }

  .col-info{
    font-size: 12px;
  }

  .form-control{
    width: 170px;
    font-size: 11px;
  }

  .btn-envio{
    margin: 0 20px;
  }
}