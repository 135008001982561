.mainAcesso{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containeer{
  display: flex;
  background-color: #fff ;
  width: 54%;
  height: 600px;
  border-radius: 15px;
  overflow: hidden;
}
.nautico{
  color: #000;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50% ;
  height: 100%;
  transition: 0.2s all ease;
}

.nautico:hover{
  background-color: rgb(189, 189, 189);
  width: 630px;
  color: #000;
  text-decoration: none;
}

.imagenautico img{
  width: 100%;
}
.imagenautico{
  width: 40%;
}

.terrestre{
  color: #000;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50% ;
  transition: 0.2s all ease;
}
.imageterrestre img{
  width: 100%;
}
.terrestre:hover{
  background-color: rgb(189, 189, 189);
  width: 630px;
  color: #000;
  text-decoration: none;
}

@media (max-width: 900px){
  
}


@media (max-width: 700px){
  .mainAcesso{
    height: 88vh;
    align-items: flex-end;
  }

  .containeer{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 550px;
  }
  .nautico{
    width: 100% ;
    height: 50%;
  }
  .terrestre{
    width: 100% ;
    height: 50%;
    transition: 0.2s all ease;
  }
  .terrestre:hover{
    width: 100%;
    height: 70%;
  }
  .nautico:hover{
    width: 100%;
    height: 70%;
  }
}