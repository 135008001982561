.main-outrasSolucoes{
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-outrasSolucoes{
  text-align: center;
  background-color: #fff;
  width: 40%;
  border-radius: 50px;
  padding: 10px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.col-outrasSolucoes ul{
  list-style: none;
}

@media (max-width: 900px) {
  .col-outrasSolucoes{
    text-align: center;
    width: 90%;
  }
}