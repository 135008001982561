.about-us-lytx{
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 300px;
}


.texto-about-lytx{
  width: 80%;
  background: #fff;
  border-radius: 50px;
  padding: 60px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 90%;
  text-align: center;
  animation: go-back 1.5s
  
}

@keyframes go-back{
  0% {
      transform: translateX(100px);
  }
  100% {
      transform: translateX(0);
  }
}

@media (max-width: 900px) {
  .about-us-lytx{
    height: 300px;
  }
  .texto-about-lytx{
    width: 90%;
    padding: 40px;
    top: 45%;
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  .about-us-lytx{
    height: 300px;
  }
  .texto-about-lytx{
    width: 90%;
    padding: 40px;
    top: 35%;
    font-size: 12px;
  }
}
@media(max-width: 500px){
  .about-us-lytx{
    height: 350px;
  }
  .texto-about-lytx{
    width: 90%;
    padding: 35px;
    top: 35%;
    font-size: 12px;
  }
}

@media(max-width: 420px){
  .about-us-lytx{
    height: 350px;
  }
  .texto-about-lytx{
    width: 90%;
    padding: 35px;
    top: 30%;
    font-size: 12px;
  }
}

@media(max-width: 380px){
  .about-us-lytx{
    height: 350px;
  }
  .texto-about-lytx{
    width: 90%;
    padding: 35px;
    top: 40%;
    font-size: 12px;
  }
}