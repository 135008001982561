.featured--vertical{
  width: inherit;
  height: inherit;
  background: linear-gradient(to top, #111 10%, transparent 90%);

}

.featured--horizontal{
  background: linear-gradient(to right, #111 30%, transparent 70%);
}

.featured{
  background-image: url("../../image/banner.jpg");
  height: 100vh;
}

.message-banner{
  position: absolute;
  z-index: 9999;
  color: #fff;
  font-size: 30px;
  margin: 300px 100px 0 ;
}

.button-banner{
   height: 60px;
   width: 200px;
   background: transparent;
   border-radius: 50px;
   border: #fff solid 2px;
   color: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
}

.button-banner a{
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

@media (max-width: 900px) {
  .featured{
    height: 70vh;
  }

  .message-banner{
    margin: 25
    0px 100px;
  }
}