.about-us-preps{
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 210px;
}

.texto-about-preps{
  width: 80%;
  background: #fff;
  border-radius: 50px;
  padding: 60px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: -15%;
  text-align: center;
  animation: go-back 2.5s ;
}

@media (max-width: 900px){
  .texto-about-preps{
    width: 90%;
    font-size: 12px;
    padding: 40px;
    top: 45%;
    max-height: 160px;
  }
  .about-us-preps{
    min-height: 190px;
  }
}

@media (max-width: 700px){
  .texto-about-preps{
    width: 90%;
    font-size: 12px;
    padding: 25px;
    top: 35%;
    max-height: 140px;
  }
  .about-us-preps{
    min-height: 160px;
  }
}

@media (max-width: 500px){
  .texto-about-preps{
    width: 90%;
    font-size: 12px;
    padding: 30px;
    top: 30%;
    min-height: 210px;   
  }
  .about-us-preps{
    min-height: 160px;
  }

}
@media (max-width: 430px){
  .texto-about-preps{
    width: 90%;
    font-size: 12px;
    padding: 25px;
    top: 30%;
    min-height: 218px;
  }
  .about-us-preps{
    min-height: 190px;
  }
}
@media (max-width: 390px){
  .texto-about-preps{
    width: 90%;
    font-size: 12px;
    padding: 30px;
    top: 30%;
    min-height: 235px;
  }
}

@media (max-width: 375px){
  .texto-about-preps{
    width: 90%;
    font-size: 12px;
    padding: 25px;
    top: 37%;
    min-height: 235px;
  }
  .about-us-preps{
    min-height: 200px;
  }
}