.why-use-preps{
  display: flex;
  margin: 0 auto;
  max-width: 1600px;
  justify-content: center;
  padding: 20px;
  gap: 150px;
  flex-wrap: wrap ;
  margin-bottom: 50px;
  
}
.why-use-preps-text h4{
  text-align: center;
  margin-bottom: 20px;
  
}

.why-use-preps-text{
  text-align: center;
  width: 650px;
  align-items: center;
  animation: go-back 3.2s ;
}

/*Transition*/
.why-use-preps-text {
  transition: transform 1s;
  transform: translateX(1.5) scale(0.5);
}
.why-use-preps-text:hover,
button:focus {
  transform: translateX(10px) scale(1.2);
}

/*Responsive*/
@media (max-width: 900px) {
  .why-use-preps{
    flex-direction: column;
    gap: 65px;
    }
    .why-use-preps-text{
      font-size: 14px;
      
      padding: 0px 30px;
      width: 100%;
    }
  }